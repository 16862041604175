<template>
	<div>
		<Dialog ref="showCalcResultDialog" title="Beräkning" :sub-title="calcResultItem ? $formatter.employeeTextFromId(calcResultItem.AnstNr) : ''">
			<v-card outlined v-if="calcResultItem">
				<BilformanCalcResultCardText :calc-result="calcResultItem" :selectedVehicle="{Text: calcResultItem.BilModel, Code: calcResultItem.BilKod}"/>
			</v-card>
			<template v-slot:buttons="{hide}">
				<v-spacer></v-spacer>
				<v-btn color="secondary" @click="hide">Stäng</v-btn>
				<v-spacer></v-spacer>
			</template>
		</Dialog>

		<DataTable
						ref="table"
						:items="items"
						:headers="tableHeaders"
						:pagination="paginationHelper"
						@change:pagination="load(Year)"
						:loading="isLoading"
		>
			<template slot="table-row" slot-scope="props">
				<td class="text-left">{{$formatter.employeeTextFromId(props.item.AnstNr)}}</td>
				<td class="text-left">{{props.item.BilModel}}</td>
				<td class="text-right">{{props.item.BilYear}}</td>
				<td class="text-right">{{props.item.ErrorMessage ? '' : $formatter.decimal(props.item.BilFormon)}}</td>
				<td class="text-right">{{props.item.ErrorMessage ? '' : $formatter.decimal(props.item.BerFormanTot)}}</td>
				<td class="text-right">{{props.item.ErrorMessage ? '' : $formatter.decimal(props.item.BerFormanTot - props.item.BilFormon)}}</td>
				<td class="text-right">
					<WarningIconWithTooltip v-if="props.item.ErrorMessage" :text="props.item.ErrorMessage" small/>
					<Tooltip text="Visa beräkning">
						<v-btn small color="accent" icon @click="showCalcResult(props.item)">
							<v-icon color="accent">mdi-information-outline</v-icon>
						</v-btn>
					</Tooltip>
				</td>
			</template>
		</DataTable>
	</div>
</template>

<script>
import {mapActions} from 'vuex';

import PaginationHelper            from '../../lib/PaginationHelper.js';
import DataTable                   from '../DataTable.vue';
import Tooltip                     from '../Tooltip.vue';
import Dialog                      from '../Dialog.vue';
import WarningIconWithTooltip      from '../WarningIconWithTooltip.vue';
import BilformanCalcResultCardText from './BilformanCalcResultCardText.vue';

export default {
	name:       'RecalcVehicleBenefits',
	components: {WarningIconWithTooltip, BilformanCalcResultCardText, Dialog, Tooltip, DataTable},

	props: {
		showEmptyMsg: {
			type:    Boolean,
			default: true,
		},
	},

	data: () => ({
		isLoading:        true,
		items:            [],
		paginationHelper: new PaginationHelper({
			sortBy:                       'AnstNr',
			localStorageDescendingPrefix: 'RecalcVehicleBenefits_InitialSortDesc',
		}),
		Year: null,

		calcResultItem: null,

		tableHeaders: [
			{text: 'Anställd', value: 'AnstNr', class: 'text-left'},
			{text: 'Fordon', value: 'BilModel', class: 'text-left'},
			{text: 'Modellår', value: 'BilYear', class: 'text-right'},
			{text: 'Nuvarande belopp', value: 'BilFormon', class: 'text-right'},
			{text: 'Nytt belopp', value: 'BerFormanTot', class: 'text-right'},
			{text: 'Förändring', value: 'BerFormanTot', class: 'text-right'},
			{text: '', value: '', sortable: false},
		],
	}),

	methods: {
		...mapActions(['loadPerLista']),

		load(Year) {
			this.Year = Year;

			if(!this.Year) {
				return;
			}

			this.$tryCatch({
				task: async () => {
					this.isLoading = true;

					await this.loadPerLista();

					this.items = await this.paginationHelper.fetch(`/vehicleBenefits/recalc/${encodeURIComponent(this.Year)}`);

					if(this.items.length === 0 && this.showEmptyMsg) {
						this.$msgInfo('Det finns inga anställda i personalregistret som behöver ny beräkning av bilförmån.');
					}
				},

				finally: () => {
					this.isLoading = false;
				},
			});
		},

		anyRecalcBasisExists() {
			return this.items.filter(({ErrorMessage}) => !ErrorMessage).length > 0;
		},

		showCalcResult(item) {
			this.calcResultItem = item;
			this.$refs.showCalcResultDialog.show();
		},
	},
}
</script>

<style scoped>

</style>
